<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {}
  },
  async mounted() {
    const { to = '/', accessToken, ...rest } = this.$route.query
    const path = to
    const query = {
      ...rest,
    }
    this.$store.commit('SET_TOKEN', { accessToken })
    setTimeout(() => {
      this.$router.replace({
        path,
        query,
      })
    })
  },
}
</script>
